import React, { useRef, useEffect, Dispatch, SetStateAction } from "react";
import JapanMapSVG from "./JapanMapSVG";
import "./JapanMap.css"

function JapanMap({setPrefectureName}:Props) {

  const svgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      const prefectureElements = svgRef.current.getElementsByClassName("prefecture");

      [...prefectureElements].forEach((element) => {
        element.addEventListener("click", () => {
          const svgClassStrings = element.getAttribute("class")?.split(' ');
          if(svgClassStrings != null){
            const prefectureName = svgClassStrings[0];
            setPrefectureName(prefectureName);
          }
        });
      });
    }
  }, []);

  return (
    <div className="japan-map" ref={svgRef}>
      <JapanMapSVG />
    </div>
  );
}

type Props = {
  setPrefectureName:Dispatch<SetStateAction<string>>;
}

export default JapanMap;
