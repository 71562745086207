import React,{useState} from 'react';
import './App.css';
import JapanMap from './JapanMap/JapanMap';

function App() {
  const [prefectureName,setPrefectureName] = useState("");

  return (
    <div className="App">
      <header className="App-header">
        <p>市区町村暗記</p>
      </header>
      <body>
        <JapanMap setPrefectureName={setPrefectureName}/>
        <p>
          {prefectureName}
        </p>
        </body>
    </div>
  );
}

export default App;